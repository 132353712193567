/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Comparison, ExpertTeam, FAQ, FinOps, Form, Main, Process, Screens, TopBlock, Steps, Features, MainTexts} from '~/features/service';
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    h3: "h3"
  }, _provideComponents(), props.components);
  if (!Features) _missingMdxReference("Features", false);
  if (!Features.FuturesSection) _missingMdxReference("Features.FuturesSection", true);
  if (!Features.Item) _missingMdxReference("Features.Item", true);
  if (!FinOps) _missingMdxReference("FinOps", false);
  if (!FinOps.FinOpsSection) _missingMdxReference("FinOps.FinOpsSection", true);
  if (!FinOps.FinopsContent) _missingMdxReference("FinOps.FinopsContent", true);
  if (!FinOps.FinopsTitle) _missingMdxReference("FinOps.FinopsTitle", true);
  if (!MainTexts) _missingMdxReference("MainTexts", false);
  if (!MainTexts.MainTextsContent) _missingMdxReference("MainTexts.MainTextsContent", true);
  if (!MainTexts.MainTextsSection) _missingMdxReference("MainTexts.MainTextsSection", true);
  if (!MainTexts.MainTextsTitle) _missingMdxReference("MainTexts.MainTextsTitle", true);
  if (!Steps) _missingMdxReference("Steps", false);
  if (!Steps.Item) _missingMdxReference("Steps.Item", true);
  if (!Steps.StepsSection) _missingMdxReference("Steps.StepsSection", true);
  return React.createElement(React.Fragment, null, React.createElement(TopBlock, {
    imgSrc: "/services/azure-logo.svg",
    imgAlt: "azure logo"
  }, React.createElement(_components.h2, null, "Maximize cloud value – pay only when we deliver savings"), React.createElement(_components.p, null, "For enterprises spending $100k+ a month on cloud, we align our fees with the results we achieve. No savings, no cost.")), "\n", React.createElement(Main, null, React.createElement(MainTexts.MainTextsSection, null, React.createElement(MainTexts.MainTextsTitle, null, React.createElement(_components.h2, null, "How we solve it"), React.createElement(_components.p, null, "Streamlining cloud operations by addressing inefficiencies, aligning goals, and empowering teams to drive innovation")), React.createElement(MainTexts.MainTextsContent, null, React.createElement(_components.p, null, "Our journey with clients starts with a comprehensive initial assessment to uncover inefficiencies and potential savings."), React.createElement(_components.p, null, "From there, we craft a tailored optimization strategy that not only reduces costs but enhances the effectiveness of your cloud operations."), React.createElement(_components.p, null, "Over the course of our engagement, we work closely with your team, sharing knowledge and tools so they’re empowered to maintain and improve your cloud environment long after our collaboration ends."))), React.createElement(Steps.StepsSection, null, React.createElement(Steps.Item, {
    key: 1,
    index: 1
  }, React.createElement(_components.h3, null, "Initial Assessment"), React.createElement(_components.p, null, "Identify inefficiencies and uncover 10–15% savings immediately")), React.createElement(Steps.Item, {
    key: 2,
    index: 2
  }, React.createElement(_components.h3, null, "Optimization"), React.createElement(_components.p, null, "Tailor solutions that deliver up to 40% cost reductions")), React.createElement(Steps.Item, {
    key: 3,
    index: 3
  }, React.createElement(_components.h3, null, "Knowledge Transfer"), React.createElement(_components.p, null, "Knowledge Transfer – Train your team to achieve long-term success")), React.createElement(Steps.Item, {
    key: 4,
    index: 4
  }, React.createElement(_components.h3, null, "Transition"), React.createElement(_components.p, null, "Leave your cloud in a stronger position, with a self-sufficient team"))), React.createElement(Features.FuturesSection, null, React.createElement(Features.Item, {
    key: 0,
    index: 0
  }, React.createElement(_components.h3, null, "Cloud Assessment"), React.createElement(_components.p, null, "Identify inefficiencies and uncover 10–15% savings immediately")), React.createElement(Features.Item, {
    key: 1,
    index: 1
  }, React.createElement(_components.h3, null, "Cloud Optimization"), React.createElement(_components.p, null, "Tailor solutions that deliver up to 40% cost reductions")), React.createElement(Features.Item, {
    key: 2,
    index: 2
  }, React.createElement(_components.h3, null, "Cloud Licensing"), React.createElement(_components.p, null, "Knowledge Transfer – Train your team to achieve long-term success")), React.createElement(Features.Item, {
    key: 3,
    index: 3
  }, React.createElement(_components.h3, null, "Cloud Expert on Demand"), React.createElement(_components.p, null, "Leave your cloud in a stronger position, with a self-sufficient team")))), "\n", React.createElement(FinOps.FinOpsSection, {
    logoName: "finops_logo",
    pictureName: "tree"
  }, React.createElement(FinOps.FinopsTitle, null, React.createElement(_components.h2, null, "Aligned interests, guaranteed results"), React.createElement(_components.p, null, "Streamlining cloud operations by addressing inefficiencies, aligning goals, and empowering teams to drive innovation")), React.createElement(FinOps.FinopsContent, null, React.createElement(_components.p, null, "Our model is simple: if we don’t deliver measurable savings, you pay nothing. By following proven FinOps frameworks and best practices, we ensure every recommendation serves your bottom line. It’s a transparent, performance-based approach that keeps us fully aligned with your success."))), "\n", React.createElement(Screens), "\n", React.createElement(Comparison), "\n", React.createElement(Process), "\n", React.createElement(ExpertTeam), "\n", React.createElement(FAQ), "\n", React.createElement(Form, {
    hasLocations: true
  }, React.createElement(_components.h2, null, "Send us a message")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
