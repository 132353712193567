import { css, CSSProperties, LinariaClassName } from '@linaria/core'
import { colors, fontLeagueSpartan, fontLibreBaskerville, media, theme } from '@/lib/theme'
import { MediaProperties, pxToRem, responsiveCssProperties } from '@/utils/unitsInRem'
import { mergeResponsiveStyles } from '@/utils/mergeResponsiveStyles'

export const hBase: CSSProperties = {
  fontFamily: fontLeagueSpartan,
  fontWeight: 700,
  lineHeight: 1.1,
  letterSpacing: '-0.01em',
}

export const h1StylesBase: CSSProperties = {
  ...hBase,
  fontSize: pxToRem(36),

  [media.desktopSmall]: {
    lineHeight: 1.05,
  },
}

export type TitleVariantType = 'xxl' | 'large' | 'medium'

export const titleCommon: CSSProperties = {
  fontFamily: fontLeagueSpartan,
  lineHeight: 1.1,
  letterSpacing: '-0.01em',
}

export const titleXxlStyles: CSSProperties = mergeResponsiveStyles(
  titleCommon,
  responsiveCssProperties({ cssProperty: 'fontSize', base: 36, desktopSmall: 76 }),
  responsiveCssProperties({ cssProperty: 'fontWeight', base: '700', desktopSmall: '500' }),
)

export const TitleXxlClass = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${titleXxlStyles};
  }
`

export const titleLargeStyles: CSSProperties = mergeResponsiveStyles(
  titleCommon,
  responsiveCssProperties({ cssProperty: 'fontSize', base: 36, desktopSmall: 60 }),
  responsiveCssProperties({ cssProperty: 'fontWeight', base: '700', desktopSmall: '600' }),
)

export const TitleLargeClass = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${titleLargeStyles};
  }
`

export const titleMediumStyles: CSSProperties = mergeResponsiveStyles(
  titleCommon,
  responsiveCssProperties({ cssProperty: 'fontSize', base: 24, desktopSmall: 36 }),
  responsiveCssProperties({ cssProperty: 'fontWeight', base: '700' }),
)

export const TitleMediumClass = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${titleMediumStyles};
  }
`

export const titleSmallStyles: CSSProperties = mergeResponsiveStyles(
  titleCommon,
  responsiveCssProperties({ cssProperty: 'fontSize', base: 24 }),
  responsiveCssProperties({ cssProperty: 'fontWeight', base: '500' }),
)

export const makeH2Styles = ({ base = 24, desktopSmall = 36 }: Pick<MediaProperties, 'base' | 'desktopSmall'>) => {
  return {
    ...hBase,
    ...responsiveCssProperties({ base, desktopSmall }),
  }
}

export const makeH3Styles = ({ weight = 'normal' }: { weight?: 'normal' | 'bold' }) => {
  return {
    ...hBase,
    fontSize: pxToRem(24),
    fontWeight: weight === 'normal' ? 500 : 700,
  }
}

export type BodyVariantType = 'small' | 'normal' | 'large'

export const bodyFontSizes: Record<BodyVariantType, number> = {
  small: 14,
  normal: 16,
  large: 18,
}

export const textBaseStyles: CSSProperties = {
  fontFamily: fontLibreBaskerville,
  fontSize: pxToRem(bodyFontSizes.normal),
  fontWeight: 400,
  lineHeight: 1.65,
}

export const textCommon: CSSProperties = {
  fontFamily: fontLibreBaskerville,
  fontWeight: 400,
  lineHeight: 1.65,
}

export const textSmallStyles: CSSProperties = {
  ...textCommon,
  fontSize: pxToRem(bodyFontSizes.small),
}

export const textLargeStyles: CSSProperties = {
  ...textCommon,
  fontSize: pxToRem(bodyFontSizes.large),
}
export const textNormalStyles: CSSProperties = {
  ...textCommon,
  fontSize: pxToRem(bodyFontSizes.normal),
}

export const TextLargeClass = css`
  p {
    ${textLargeStyles};
  }
`

export const TextNormalClass = css`
  p {
    ${textNormalStyles};
  }
`

export const TextSmallClass = css`
  p {
    ${textSmallStyles};
  }
`

export const makeTextBaseStyles = ({ variant = 'normal' }: { variant?: BodyVariantType }) => ({
  ...textBaseStyles,
  fontSize: pxToRem(bodyFontSizes[variant]),
})

export const textLinkStyles: CSSProperties = {
  color: colors.secondary.darkest,
  transitionTimingFunction: 'ease-in-out',
  transitionDuration: '0.2s',
  transitionProperty: 'color',
  textDecoration: 'underline',

  [media.hover]: {
    '&:hover': {
      color: theme.hover,
    },
  },
  '&:active': {
    textDecoration: 'none',
    color: theme.active,
  },
}

export const mainElementOffsets: CSSProperties = {
  margin: '32px 0',

  [media.desktopSmall]: {
    margin: 'var(--content-blocks-gap) 0',
  },

  '& + *': {
    marginTop: '0 !important',
  },
}

export const textStylesPalette: Record<BodyVariantType, LinariaClassName> = {
  large: TextLargeClass,
  normal: TextNormalClass,
  small: TextSmallClass,
}

export const titleStylesPalette: Record<TitleVariantType, LinariaClassName> = {
  xxl: TitleXxlClass,
  large: TitleLargeClass,
  medium: TitleMediumClass,
}
