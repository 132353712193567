import { CSSProperties } from '@linaria/core'

export function mergeResponsiveStyles(...styles: CSSProperties[]): CSSProperties {
  const result: CSSProperties = {}

  styles.forEach(style => {
    Object.entries(style).forEach(([key, value]) => {
      if (typeof value === 'object' && !Array.isArray(value)) {
        result[key] = {
          ...(result[key] as object),
          ...value,
        }
      } else {
        result[key] = value
      }
    })
  })

  return result
}
