import React, { ReactNode, useContext } from 'react'
import { styled } from '@linaria/react'
import { colors, fontLeagueSpartan } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'
import { Checkbox } from './Checkbox'
import { FormAppearanceContext } from '../../FormContext'
import { AppearanceType } from '../../types'

interface Props {
  isChecked: boolean
  toggleChecked: () => void
  children: ReactNode
  isPrimary?: boolean
}

export const AgreementBase = ({ isChecked, toggleChecked, children, isPrimary = false }: Props) => {
  const appearance = useContext(FormAppearanceContext)

  return (
    <Container>
      <CheckboxStyled isChecked={isChecked} onChange={toggleChecked} appearance={appearance} isPrimary={isPrimary} />
      <Text $appearance={appearance}>{children}</Text>
    </Container>
  )
}

const Container = styled.label`
  font-family: ${fontLeagueSpartan};
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
`

const CheckboxStyled = styled(Checkbox)`
  margin-right: 12px;
`

const Text = styled.div<{ $appearance: AppearanceType }>`
  font-size: ${pxToRem(20)};
  font-weight: 400;
  letter-spacing: -0.4px;
  color: ${({ $appearance }) =>
    $appearance === 'dark' ? colors.textStyles.inverseTransparent : colors.textStyles.regular};
`
