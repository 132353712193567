import React from 'react'
import { styled } from '@linaria/react'
import { StandardLonghandProperties } from 'csstype'
import { Styleable, WithChildren } from '@/lib/interfaces'
import { getValue, MediaProperties } from '@/utils/unitsInRem'
import { media } from '@/lib/theme'

type AllowedMediaProperties = Pick<MediaProperties, 'base' | 'desktopSmall'>

interface StackBaseProps {
  direction?: StandardLonghandProperties['flexDirection']
  gap?: AllowedMediaProperties | (number | string)
}

interface StackProps extends WithChildren, StackBaseProps, Styleable {}

export const Stack = ({ children, direction, gap, className }: StackProps) => {
  return (
    <StackView className={className} $direction={direction} $gap={gap}>
      {children}
    </StackView>
  )
}

const StackView = styled.div<{
  $direction?: StandardLonghandProperties['flexDirection']
  $gap?: MediaProperties | (number | string)
}>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction ?? 'column'};
  gap: ${({ $gap }) => calculateGap($gap, 'base')};

  ${media.desktopSmall} {
    gap: ${({ $gap }) => calculateGap($gap, 'desktopSmall')};
  }
`

function calculateGap(
  gap: AllowedMediaProperties | (number | string),
  currentMedia: keyof AllowedMediaProperties,
): string | 'unset' {
  if (gap === undefined || gap === null) return 'unset'

  if (typeof gap === 'string') return gap
  if (typeof gap === 'number') return getValue(gap)

  const value =
    currentMedia in gap && gap[currentMedia] !== undefined
      ? gap[currentMedia]
      : gap.base

  return value !== undefined && value !== null ? getValue(value) : 'unset'
}