import React from 'react'
import { css, cx } from '@linaria/core'
import { Button } from '@/UI'
import { useBookACall } from '@/hooks'
import { Styleable } from '@/lib/interfaces'

interface Props extends Styleable {
  isCentered?: boolean
}

export const BookAFreeMeetingButton = ({ className, isCentered = true }: Props) => {
  const bookACallHandler = useBookACall()

  return (
    <Button
      className={cx(
        isCentered &&
          css`
            align-self: center;
          `,
        className,
      )}
      size="large"
      type="filled"
      onClick={bookACallHandler}
    >
      Book a free meeting
    </Button>
  )
}
