exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-podcast-tsx": () => import("./../../../src/pages/podcast.tsx" /* webpackChunkName: "component---src-pages-podcast-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-test-page-tsx": () => import("./../../../src/pages/test-page.tsx" /* webpackChunkName: "component---src-pages-test-page-tsx" */),
  "component---src-pages-video-tsx": () => import("./../../../src/pages/video.tsx" /* webpackChunkName: "component---src-pages-video-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-podcast-tsx": () => import("./../../../src/templates/podcast.tsx" /* webpackChunkName: "component---src-templates-podcast-tsx" */),
  "component---src-templates-service-new-jsx-content-file-path-content-blocks-form-sideblock-md": () => import("./../../../src/templates/service-new.jsx?__contentFilePath=/Users/sasha/Projects/samexpert-com/content/blocks/form-sideblock.md" /* webpackChunkName: "component---src-templates-service-new-jsx-content-file-path-content-blocks-form-sideblock-md" */),
  "component---src-templates-service-new-jsx-content-file-path-content-blocks-locations-md": () => import("./../../../src/templates/service-new.jsx?__contentFilePath=/Users/sasha/Projects/samexpert-com/content/blocks/locations.md" /* webpackChunkName: "component---src-templates-service-new-jsx-content-file-path-content-blocks-locations-md" */),
  "component---src-templates-service-new-jsx-content-file-path-content-posts-services-finops-azure-cost-optimisation-mdx": () => import("./../../../src/templates/service-new.jsx?__contentFilePath=/Users/sasha/Projects/samexpert-com/content/posts/services/finops-azure-cost-optimisation.mdx" /* webpackChunkName: "component---src-templates-service-new-jsx-content-file-path-content-posts-services-finops-azure-cost-optimisation-mdx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */)
}

