import { graphql, useStaticQuery } from 'gatsby'

export function useGetContentFromMdFile(contentFileName: string) {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { ne: null } }) {
        nodes {
          fileAbsolutePath
          html
        }
      }
    }
  `)

  const markdownNode = data.allMarkdownRemark.nodes.find(node => node.fileAbsolutePath.includes(contentFileName))

  return markdownNode?.html.replaceAll('\n', '').trim()
}
