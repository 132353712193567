import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { schemaOrgOrganization } from '@/utils/schema.org'
import { SEO, Layout } from '@/blocks/layout'
import { Contact } from '~/features/info'

const pageTitle = 'Get in touch with SAMexpert'
const pageDescription =
  'Use this form to send us a message, call us at the numbers published here, or contact our local offices. We are always happy to help.'

const ContactPage = ({ data }: PageProps<Queries.ContactPageQueryQuery>) => {
  return (
    <Layout breadcrumbsText="Home" breadcrumbsLink="/" hasNoVerticalMargins>
      <Contact afterBlock={data.snippetAfter.content} titleBlock={data.snippetTitleBlock.content} />
    </Layout>
  )
}

export default ContactPage

export const Head = ({ data }) => {
  const defaultImage = data.contentfulDefaultImage
  const seoImage = defaultImage.image.fixed ? getImage(defaultImage.image.fixed).images.fallback.src : null

  return (
    <SEO
      title={pageTitle}
      description={pageDescription}
      image={seoImage}
      richresult={schemaOrgOrganization(data.site.siteMetadata)}
    />
  )
}

export const pageQuery = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        email
      }
    }
    snippetTitleBlock: contentfulSnippet(contentfulid: { eq: "contactpage-top-title-block" }) {
      content {
        raw
      }
    }
    snippetAfter: contentfulSnippet(contentfulid: { eq: "contactpage-bottom" }) {
      content {
        raw
      }
    }

    contentfulDefaultImage(for: { eq: "ContactUs" }) {
      image {
        fixed: gatsbyImageData(
          width: 1200
          height: 630
          placeholder: BLURRED
          layout: FIXED
          formats: [AUTO]
          quality: 10
        )
      }
    }
  }
`
