import React from 'react'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'
import { colors, desktopSmallMedia, media } from '@/lib/theme'
import { ContactForm } from './FormContent'
import { TitleBlockMDX, Wrapper } from '@/UI'
import { Styleable, WithChildren } from '@/lib/interfaces'
import { BookCallSection } from './BookCallSection'
import { Locations } from './Locations'

interface FormWrapperProps extends WithChildren, Styleable {
  hasLocations?: boolean
  contentFileName?: string
  isSideBlockTransparentBg?: boolean
}

export const ContactFormWithWrapper = ({
  children,
  className,
  hasLocations,
  contentFileName,
  isSideBlockTransparentBg,
}: FormWrapperProps) => {
  return (
    <FormWrapperView $noMobilePadding $hasSideBlock className={className}>
      <FormBlockView>
        <TitleBlockMDX
          titleColor="inverse"
          subtitleColor="inverse"
          titleStyle="medium"
          textStyle="normal"
          alignment="start"
        >
          {children}
        </TitleBlockMDX>
        <ContactForm />
      </FormBlockView>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: end;
        `}
      >
        <BookCallSection isNoBgOnMobile={isSideBlockTransparentBg} contentFileName={contentFileName} />
        {hasLocations && <Locations />}
      </div>
    </FormWrapperView>
  )
}

const FormWrapperView = styled(Wrapper)<{ $hasSideBlock: boolean }>`
  display: grid;
  grid-area: newForm;

  ${desktopSmallMedia} {
    grid-column-gap: 48px;
    grid-template-columns: ${({ $hasSideBlock }) => ($hasSideBlock ? '58% auto' : '100%')};
  }
`

const FormBlockView = styled.div`
  display: grid;
  grid-gap: 2rem;
  padding: 3rem var(--layout-indent);
  width: 100%;
  background-color: ${colors.secondary.secondary};

  ${media.desktopSmall} {
    padding: 3rem;
    border-radius: 1rem;
  }
`
