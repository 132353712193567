import React, { ReactNode } from 'react'
import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import { MacScrollbar } from 'mac-scrollbar'
import { Wrapper, PortalWrapper } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { ButtonContactCommon } from './ButtonContactCommon'

interface Props {
  children: ReactNode[]
  closeHandler: () => void
  isVisible: boolean
  renderInDOM?: boolean
}

export const PortalWrapperHeaderContent = ({ children, isVisible, closeHandler, renderInDOM }: Props) => {
  return (
    <PortalWrapper
      role="navigation"
      isVisible={isVisible}
      renderInDOM={renderInDOM}
      closeHandler={closeHandler}
      initialHeight="var(--header-h)"
    >
      <WrapperStyled $noMobilePadding={false}>
        <TopBlock>{children[0]}</TopBlock>
        <MacScrollbar className={contentInner} skin="dark">
          {children[1]}
        </MacScrollbar>
        <ContactBtnMobile />
      </WrapperStyled>
    </PortalWrapper>
  )
}

const contentInner = css`
  display: flex;
  padding-bottom: 4rem;
  flex-grow: 1;
  ${desktopSmallMedia} {
    margin: 0 135px;
  }
`

const TopBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--header-h);
  flex-direction: row-reverse;
  flex-shrink: 0;

  ${desktopSmallMedia} {
    flex-direction: row;
  }
`

const ContactBtnMobile = styled(ButtonContactCommon)`
  display: flex;
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: 24px;

  ${desktopSmallMedia} {
    display: none;
  }
`

const WrapperStyled = styled(Wrapper)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${colors.secondary.secondary};
  max-height: 100%;
`
