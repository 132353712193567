import React from 'react'
import { styled } from '@linaria/react'
import { Button, TitleBlockMDX } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { useBookACall, useGetContentFromMdFile } from '@/hooks'

interface Props {
  isNoBgOnMobile?: boolean
  contentFileName?: string
}

export const BookCallSection = ({ isNoBgOnMobile, contentFileName }: Props) => {
  const clickHandler = useBookACall()

  const contentFileNameFinal = contentFileName ?? 'form-sideblock'
  const html = useGetContentFromMdFile(contentFileNameFinal)

  if (!html) return null

  return (
    <Root $isNoBoOnMob={isNoBgOnMobile}>
      <TitleBlockMDX html={html} titleStyle="medium" textStyle="normal" alignment="start" />
      <Button type="secondary" onClick={clickHandler} desktopWidth="286px">
        Book a call
      </Button>
    </Root>
  )
}

const Root = styled.div<{ $isNoBoOnMob: boolean }>`
  display: grid;
  grid-row-gap: 32px;
  background-color: ${({ $isNoBoOnMob }) => ($isNoBoOnMob ? 'transparency' : colors.primary.lightest)};
  padding: ${({ $isNoBoOnMob }) =>
    $isNoBoOnMob
      ? '40px var(--layout-indent) 0 var(--layout-indent)'
      : '40px var(--layout-indent) 40px var(--layout-indent)'};
  margin-bottom: 32px;

  ${desktopSmallMedia} {
    margin-bottom: 48px;
    background-color: transparent;
    padding: unset;
  }
`
