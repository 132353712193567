import React from 'react'
import { styled } from '@linaria/react'
import { colors, media } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'
import { ServiceIcon } from '~/features/service/sections/main/components/ServiceIcon'
import { SectionTextStyles } from '~/features/service/components'
import { WithChildren } from '@/lib/interfaces'

interface Props extends WithChildren {
  index: number
}

const Item = ({ index, children }: Props) => {
  return (
    <FeatureView>
      <IconContainer>
        <ServiceIcon index={index} />
      </IconContainer>
      <TextsContainer>{children}</TextsContainer>
    </FeatureView>
  )
}

const FuturesSection = styled.div`
  display: grid;
  border-radius: 1.5rem;
  color: ${colors.white};
  grid-gap: 3rem;
  background-color: ${colors.secondary.secondary};
  padding: ${pxToRem(32, 48)};
  align-items: start;

  ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${media.desktopSmall} {
    padding: ${pxToRem(56, 80)};
    grid-template-columns: repeat(4, 1fr);
  }
`

const FeatureView = styled.div`
  display: grid;
  grid-template-rows: 40px auto;
  grid-gap: 1.5rem;
`

const TextsContainer = styled(SectionTextStyles)`
  display: grid;
  grid-row-gap: 0.5rem;
`

const IconContainer = styled.div`
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
`

export const Features = { Item, FuturesSection }
