import React from 'react'
import { styled } from '@linaria/react'
import { ArticlesAnnouncements, Cases, Factoids, Highlights, LastVideos, OurClients, OurPartners } from './components'
import { ContactFormWithWrapper } from '@/blocks/contact-form'
import { Break } from '@/UI'

export const Home = () => {
  return (
    <HomeView>
      <Highlights />
      <Factoids />
      <Cases />
      <OurClients />
      <ContactFormWithWrapper>
        <h2>Let’s begin our conversation:</h2>
      </ContactFormWithWrapper>
      <Break $indent={72} />
      <ArticlesAnnouncements />
      <LastVideos />
      <OurPartners />
    </HomeView>
  )
}

const HomeView = styled.div`
  display: flex;
  flex-direction: column;
`
