import 'mac-scrollbar/dist/mac-scrollbar.css'

import React from 'react'
import { Sprite } from './src/shared/lib/Sprite'

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Sprite />
      {element}
    </>
  )
}
