import React from 'react'
import { styled } from '@linaria/react'
import { cx, LinariaClassName } from '@linaria/core'
import {
  makeTextBaseStyles,
  TitleLargeClass,
  TitleMediumClass,
  TitleXxlClass,
  BodyVariantType,
  TitleVariantType,
  TextLargeClass,
  TextNormalClass,
  TextSmallClass,
  titleStylesPalette,
  textStylesPalette,
} from '@/UI'
import { colors } from '@/lib/theme'
import { Styleable, WithChildren } from '@/lib/interfaces'

export type ColorType = 'normal' | 'inverse' | 'gray'
type TextAlignType = 'start' | 'center'

const blockPalette: Record<ColorType, string> = {
  normal: colors.textStyles.regular,
  inverse: colors.textStyles.inverse,
  gray: colors.tertiary.tertiary,
}

interface CommonProps extends Styleable {
  alignment?: TextAlignType
  titleColor?: ColorType
  subtitleColor?: ColorType
  titleStyle?: TitleVariantType
  textStyle?: BodyVariantType
}

interface ChildrenProps extends CommonProps {
  children: React.ReactNode
  html?: never
}

interface HTMLProps extends CommonProps {
  html: string
  children?: never
}

type Props = ChildrenProps | HTMLProps

export const TitleBlockMDX = ({
  children,
  html,
  className,
  titleColor = 'normal',
  subtitleColor = 'normal',
  alignment = 'center',
  titleStyle = 'large',
  textStyle = 'large',
}: Props) => {
  if (!children && !html) return null

  return (
    <TitleBlockView
      $alignment={alignment}
      className={cx(titleStylesPalette[titleStyle], textStylesPalette[textStyle], className)}
      $titleColor={blockPalette[titleColor]}
      $subtitleColor={blockPalette[subtitleColor]}
      {...(html ? { dangerouslySetInnerHTML: { __html: html } } : {})}
    >
      {html ? null : children}
    </TitleBlockView>
  )
}
const TitleBlockView = styled.div<{
  $alignment: TextAlignType
  $titleColor: string
  $subtitleColor: string
}>`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-items: ${({ $alignment }) => $alignment};

  & h2 {
    text-align: ${({ $alignment }) => $alignment};
    color: ${({ $titleColor }) => $titleColor};
  }

  & p {
    color: ${({ $subtitleColor }) => $subtitleColor};
    text-align: ${({ $alignment }) => $alignment};
  }
`
