import React from 'react'
import { styled } from '@linaria/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from '@linaria/core'
import { media } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'
import { TitleBlockMDX, Typography } from '@/UI'
import { useGetImagesFromAssets } from '@/hooks'
import { SectionContainerMDX } from '../components'
import { WithChildren } from '@/lib/interfaces'

interface Props extends WithChildren {
  logoName: string
  pictureName: string
}

export const FinOpsSection = ({ children, logoName, pictureName }: Props) => {
  const logo = useGetImagesFromAssets({ name: logoName })[0]
  const picture = useGetImagesFromAssets({ name: pictureName })[0]

  if (!Array.isArray(children)) return null

  return (
    <SectionContainerMDX appearance="gray">
      <FinOpsView>
        <Texts>
          {children}
          <GatsbyImage
            image={logo.childImageSharp.gatsbyImageData}
            alt="FinOps logo"
            className={css`
              width: ${pxToRem(120)};
              height: ${pxToRem(39)};
            `}
            imgStyle={{
              mixBlendMode: 'darken',
            }}
          />
        </Texts>
        <GatsbyImage
          image={picture.childImageSharp.gatsbyImageData}
          alt="FinOps visual"
          imgStyle={{
            objectFit: 'contain',
            objectPosition: '50% 50%',
          }}
          className={css`
            max-width: ${pxToRem(640)};
          `}
          objectFit="contain"
        />
      </FinOpsView>
    </SectionContainerMDX>
  )
}

const FinopsTitle = ({ children }: WithChildren) => (
  <TitleBlockMDX alignment="start" subtitleColor="gray">
    {children}
  </TitleBlockMDX>
)

const FinopsContent = ({ children }: WithChildren) => <Text $bodyVariant="small">{children}</Text>

const FinOpsView = styled.div`
  display: grid;
  grid-row-gap: 2rem;
  grid-column-gap: 4.5rem;

  ${media.desktopSmall} {
    align-content: center;
    align-items: center;
    grid-template-columns: auto ${pxToRem(532)};
  }
`

const Texts = styled.div`
  grid-gap: 1.5rem;
  display: grid;
`

const Text = styled(Typography)`
  padding: ${pxToRem(24, 0, 20, 24)};
  border-left: 1px solid rgba(39, 146, 96, 0.3);

  ${media.desktopSmall} {
    padding: ${pxToRem(24, 0, 20, 32)};
  }
`

export const FinOps = { FinOpsSection, FinopsTitle, FinopsContent }
