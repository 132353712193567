import React from 'react'
import { styled } from '@linaria/react'
import { colors, fontLibreBaskerville, media } from '@/lib/theme'
import { titleSmallStyles, Wrapper } from '@/UI'
import { pxToRem } from '@/utils/unitsInRem'
import { useGetContentFromMdFile } from '@/hooks'

export const Locations = () => {
  const html = useGetContentFromMdFile('locations')

  if (!html) return null

  return (
    <Wrapper $noDesktopPadding>
      <LocationsView dangerouslySetInnerHTML={{ __html: html }} />
    </Wrapper>
  )
}

const LocationsView = styled.div`
  & ul {
    display: grid;
    grid-gap: 24px;
    padding-top: 32px;
    border-top: 2px solid ${colors.tertiary.lightest};
    margin: 0 auto;

    ${media.tablet} {
      grid-template-columns: repeat(2, 1fr);
    }

    ${media.desktopSmall} {
      grid-template-columns: unset;
      margin: unset;
      padding-top: 48px;
    }

    ${media.desktopMedium} {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  & li {
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
    border-radius: 16px;
    padding: 24px;
    gap: 8px;

    ${media.desktopSmall} {
      padding: 32px;
      gap: 16px;
    }
  }

  & h3 {
    ${titleSmallStyles};
  }

  & p,
  a {
    font-family: ${fontLibreBaskerville};
    font-weight: 400;
    line-height: 1.63;
  }

  & p {
    font-size: ${pxToRem(14)};

    &:last-child {
      margin-top: auto;
    }
  }

  & a {
    font-size: ${pxToRem(16)};
    text-decoration: underline;
    align-items: flex-end;
  }
`
