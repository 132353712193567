import React, { useCallback } from 'react'
import { styled } from '@linaria/react'
import { useSnapshot } from 'valtio'
import { media, theme } from '@/lib/theme'
import { formPrivacy, togglePrivacyAgree } from '../formState'
import { AgreementBase } from './AgreementBase'

export const PrivacyAgreement = () => {
  const { agree } = useSnapshot(formPrivacy)


  const LinkClickHandler = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation()
  }, [])

  return (
    <AgreementBase isChecked={agree} toggleChecked={togglePrivacyAgree} isPrimary>
      <JustText>I agree to the </JustText>
      <LinkStyled href="/privacy/" target="_blank" onClick={LinkClickHandler}>
        Privacy Policy
      </LinkStyled>
    </AgreementBase>
  )
}

const JustText = styled.span``

const LinkStyled = styled.a`
  text-decoration: underline;
  transition: color 0.2s ease-in-out;

  ${media.hover} {
    &:hover {
      color: ${theme.hover};
    }
  }
`
