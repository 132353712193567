import { StandardLonghandProperties, StandardShorthandProperties } from 'csstype'
import { media, MqType } from '@/lib/theme'

export function pxToRem(...values: [number, number?, number?, number?]): string {
  return values.map(value => `${value / 16}rem`).join(' ')
}

type MqTypeExt = 'base' | MqType

export interface MediaProperties extends Partial<Record<MqTypeExt, number | string>> {}

export interface ResponsiveCssProperties extends MediaProperties {
  cssProperty?: keyof StandardLonghandProperties | keyof StandardShorthandProperties
}

export const getValue = (value: number | string) => (typeof value === 'number' ? pxToRem(value) : value)

export function responsiveCssProperties({
  cssProperty = 'fontSize',
  ...values
}: ResponsiveCssProperties) {

  const result = {}

  for (const key in values) {
    const value = values[key as MqTypeExt]
    if (typeof value === 'undefined' || value === null) continue

    if (key === 'base') {
      result[cssProperty] = getValue(value)
    } else {
      result[media[key as MqType]] = {
        [cssProperty]: getValue(value),
      }
    }
  }

  return result
}
