/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    h3: "h3",
    p: "p",
    br: "br",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.h3, null, "SAMexpert HQ"), "\n", React.createElement(_components.p, null, "New Imperial House", React.createElement(_components.br), "\n", "Station Road", React.createElement(_components.br), "\n", "Stroud", React.createElement(_components.br), "\n", "Gloucestershire", React.createElement(_components.br), "\n", "GL5 3AR", React.createElement(_components.br), "\n", "United Kingdom"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "tel:+442033577120"
  }, "+44 20 3357 7120")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.h3, null, "North America"), "\n", React.createElement(_components.p, null, "1600 Broadway", React.createElement(_components.br), "\n", "Denver, CO", React.createElement(_components.br), "\n", "80202", React.createElement(_components.br), "\n", "USA"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "tel:+14042828438"
  }, "+1 (404) 282 8438")), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
