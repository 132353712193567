import React from 'react'
import { styled } from '@linaria/react'
import { BookAFreeMeetingButton, SectionContainer } from '~/features/service/components'
import { WithChildren } from '@/lib/interfaces'
import { media } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'

interface Props extends WithChildren {}

export const Main = ({ children }: Props) => {
  return (
    <SectionContainer>
      <MainBlocksWrapper>{children}</MainBlocksWrapper>
      <MainBlockButton />
    </SectionContainer>
  )
}

const MainBlocksWrapper = styled.div`
  display: grid;
  grid-row-gap: 3rem;

  ${media.desktopSmall} {
    grid-row-gap: 4.5rem;
  }
`

const MainBlockButton = styled(BookAFreeMeetingButton)`
  margin-top: 2rem;

  ${media.desktopSmall} {
    margin-top: ${pxToRem(72)};
  }
`
