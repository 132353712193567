import { styled } from '@linaria/react'
import { colors, fontLeagueSpartan, media } from '@/lib/theme'
import { makeTextBaseStyles, BodyVariantType, bodyFontSizes, makeH3Styles } from '@/UI'

export const SmallTitle = styled.span<{
  $isInverse?: boolean
  $isNotFat?: boolean
  $isCentered?: boolean
}>`
  font-family: ${fontLeagueSpartan};
  font-size: 1.5rem;
  font-style: normal;
  line-height: 1.1;
  flex-shrink: 0;
  flex-grow: 0;
  letter-spacing: -0.01em;
  font-weight: ${({ $isNotFat }) => ($isNotFat ? 500 : 700)};
  color: ${({ $isInverse }) => ($isInverse ? colors.textStyles.inverse : colors.textStyles.regular)};
  text-align: start;

  ${media.desktopSmall} {
    text-align: ${({ $isCentered }) => ($isCentered ? 'center' : 'start')};
  }
`

interface TextStylesProps {
  $bodyVariant?: BodyVariantType
  $h3Weight?: 'normal' | 'bold'
  $isH3Centered?: boolean
}

export const SectionTextStyles = styled.div<TextStylesProps>`
  & p {
    ${makeTextBaseStyles({})};
    font-size: ${({ $bodyVariant = 'normal' }) => bodyFontSizes[$bodyVariant]}px;
  }

  & h3 {
    ${makeH3Styles({})};
    font-weight: ${({ $h3Weight = 'bold' }) => ($h3Weight === 'bold' ? 700 : 500)};

    ${media.desktopSmall} {
      text-align: ${({ $isH3Centered }) => ($isH3Centered ? 'center' : 'start')};
    }
  }
`
