import React, { FC } from 'react'
import { styled } from '@linaria/react'
import { colors, media } from '@/lib/theme'

interface ArrowButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  rotate?: boolean
}

export const ArrowButton: FC<ArrowButtonProps> = ({ rotate = false, ...props }) => {
  return (
    <StyledButton $hasRotation={rotate} {...props}>
      <svg viewBox="0 0 64 64">
        <path d="M 27.3867,47.0933 42.48,32 27.3867,16.9067 25.52,18.8 38.72,32 25.52,45.2 Z" />
      </svg>
    </StyledButton>
  )
}

const StyledButton = styled.button<{ $hasRotation?: boolean }>`
  border: none;
  cursor: pointer;
  padding: 0;
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  ${media.desktopSmall} {
    display: flex;
  }

  &:focus {
    outline: none;
  }

  svg {
    width: 4rem;
    height: 4rem;
    fill: ${colors.white};
    transform: ${({ $hasRotation }) => ($hasRotation ? ' rotate(180deg)' : '')};
    transition: fill 0.3s ease-in-out;

    ${media.hover} {
      &:hover {
        fill: ${colors.primary.primary};
      }
    }

    &:active {
      fill: ${colors.primary.primary};
    }
  }
`
