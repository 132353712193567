import React from 'react'
import { styled } from '@linaria/react'
import { Styleable } from '@/lib/interfaces'
import { doNotPrintThisBlock } from '@/lib/sharedStyles'
import type { AppearanceType } from './types'
import { FormAppearanceContext } from './FormContext'
import { InputsGroup, NewsletterConsent, PrivacyAgreement, Submit } from './components'

interface Props extends Styleable {
  appearance?: AppearanceType
}

export const ContactForm = ({ className, appearance }: Props) => {
  return (
    <FormAppearanceContext.Provider value={appearance ?? 'dark'}>
      <Container className={className}>
        <InputsGroup />
        <AgreementGroup>
          <PrivacyAgreement />
          <NewsletterConsent />
        </AgreementGroup>
        <Submit />
      </Container>
    </FormAppearanceContext.Provider>
  )
}

const Container = styled.form`
  display: grid;
  grid-row-gap: 32px;
  ${doNotPrintThisBlock};
`

const AgreementGroup = styled.div`
  display: grid;
  grid-row-gap: 16px;
`
