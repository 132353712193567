import React from 'react'
import { ContactFormWithWrapper } from '@/blocks/contact-form'
import { SectionContainerMDX } from '../components'
import { WithChildren } from '@/lib/interfaces'

interface Props extends WithChildren {
  hasLocations?: boolean
  contentFileName?: string
}

export const Form = ({ children, hasLocations, contentFileName }: Props) => {
  return (
    <SectionContainerMDX appearance="gray" hasNoWrapper contentIsCentered>
      <ContactFormWithWrapper hasLocations={hasLocations} isSideBlockTransparentBg contentFileName={contentFileName}>
        {children}
      </ContactFormWithWrapper>
    </SectionContainerMDX>
  )
}
