import React from 'react'
import { styled } from '@linaria/react'
import { BookAFreeMeetingButton, SectionContainerMDX } from '../components'
import { pxToRem } from '@/utils/unitsInRem'
import { media } from '@/lib/theme'
import { Stack, TitleBlockMDX, Wrapper } from '@/UI'
import { WithChildren } from '@/lib/interfaces'

interface Props extends WithChildren {
  imgSrc: string
  imgAlt: string
}

export const TopBlock = ({ children, imgSrc, imgAlt }: Props) => {
  return (
    <StyledSectionContainer appearance="dark" hasNoWrapper>
      <ImageBlock>
        <img src={imgSrc} alt={imgAlt} />
      </ImageBlock>
      <Wrapper>
        <Inner gap={{ base: '2rem', desktopSmall: '3rem' }}>
          <StyledTitleBlock alignment="start" titleColor="inverse" subtitleColor="inverse">
            {children}
          </StyledTitleBlock>
          <BookAFreeMeetingButton isCentered={false} />
        </Inner>
      </Wrapper>
    </StyledSectionContainer>
  )
}

const StyledSectionContainer = styled(SectionContainerMDX)`
  overflow: hidden;
  display: flex;

  ${media.desktopSmall} {
    align-items: center;
    justify-content: center;
    height: ${pxToRem(611)};
  }
`

const StyledTitleBlock = styled(TitleBlockMDX)`
  ${media.desktopSmall} {
    margin-top: 0;
    max-width: ${pxToRem(600)};
  }
`

const Inner = styled(Stack)`
  position: relative;
  overflow: hidden;
  margin-top: 22rem;

  ${media.desktopSmall} {
    margin-top: 0;
    padding: ${pxToRem(0, 80)};
  }
`

const ImageBlock = styled.div`
  position: absolute;
  right: 0;
  width: 30rem;
  top: 8rem;

  ${media.desktopSmall} {
    top: 128px;
    width: ${pxToRem(855)};
    height: ${pxToRem(611)};
  }
`
