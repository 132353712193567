import React from 'react'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'
import { graphql, useStaticQuery } from 'gatsby'
import { RichTextContentType, RichTextImproved } from '@/blocks/rich-text-improoved'
import { Wrapper } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { ContactFormWithWrapper } from '@/blocks/contact-form'

interface Props {
  titleBlock: RichTextContentType
  afterBlock: RichTextContentType
}

export const Contact = ({ afterBlock, titleBlock }: Props) => {
  return (
    <ContactView>
      <WrapperStyled>
        <RichTextImproved content={titleBlock} />
      </WrapperStyled>
      <ContactFormWithWrapper className={FormWrapperStyles} hasLocations isSideBlockTransparentBg>
        <h2>Send us a message</h2>
      </ContactFormWithWrapper>
      <WrapperBottomTexts>
        <BottomTexts content={afterBlock} />
      </WrapperBottomTexts>
    </ContactView>
  )
}

const ContactView = styled.div`
  display: flex;
  flex-direction: column;
`

const WrapperStyled = styled(Wrapper)`
  margin-top: 48px;
  margin-bottom: 48px;
  display: grid;
  grid-row-gap: 24px;

  ${desktopSmallMedia} {
    margin-top: 64px;
    margin-bottom: 56px;
  }
`

const FormWrapperStyles = css`
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: ${colors.background.grayLightest};

  ${desktopSmallMedia} {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`

const WrapperBottomTexts = styled(Wrapper)`
  margin-top: 48px;
  margin-bottom: 48px;

  ${desktopSmallMedia} {
    margin-top: 64px;
    margin-bottom: 64px;
  }
`

const BottomTexts = styled(RichTextImproved)``
