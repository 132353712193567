import React from 'react'
import { styled } from '@linaria/react'
import { pxToRem } from '@/utils/unitsInRem'
import { TitleBlockMDX, Typography } from '@/UI'
import { colors, media } from '@/lib/theme'
import { WithChildren } from '@/lib/interfaces'

const MainTextsSection = ({ children }: WithChildren) => {
  if (!Array.isArray(children)) return null

  return (
    <MainTextsView>
      {children}
    </MainTextsView>
  )
}

const MainTextsTitle = ({ children }: WithChildren) => (
  <TitleBlockMDX alignment="start" subtitleColor="gray">
    {children}
  </TitleBlockMDX>
)

const MainTextsContent = ({ children }: WithChildren) => <TextBlock $bodyVariant="normal">{children}</TextBlock>

const MainTextsView = styled.div`
  display: grid;
  grid-row-gap: 2rem;
  grid-column-gap: ${pxToRem(80)};
  align-items: center;

  ${media.desktopSmall} {
    padding: ${pxToRem(0, 80)};
    grid-template-columns: 38% auto;
  }
`

const TextBlock = styled(Typography)`
  ${media.desktopSmall} {
    padding: ${pxToRem(24, 0, 24, 32)};
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-50%);
      width: 1px;
      height: 100%;
      background: linear-gradient(
        to bottom,
        transparent,
        ${colors.primary.lightest} 10%,
        ${colors.primary.lightest} 90%,
        transparent
      );
    }
  }
`

export const MainTexts = { MainTextsSection, MainTextsTitle, MainTextsContent }
