import React from 'react'
import { styled } from '@linaria/react'
import { colors, fontLeagueSpartan, media } from '@/lib/theme'
import { SectionTextStyles } from '../../../components'
import { pxToRem } from '@/utils/unitsInRem'
import { WithChildren } from '@/lib/interfaces'

interface Props extends WithChildren {
  index: number
}

const Item = ({ index, children }: Props) => {
  return (
    <SingleStepView>
      <GraphicsBlock>
        <Cylinder>{index}</Cylinder>
      </GraphicsBlock>
      <TextsBlock $isH3Centered>{children}</TextsBlock>
    </SingleStepView>
  )
}

const gradient = 'transparent 0%, black 40%, black 100%'

const SingleStepView = styled.div`
  --step-line-solid: 2px solid ${colors.secondary.secondary};
  --step-line-dashed: 1px dashed #43acda;
  --step-cylinder-size: 3rem;
  display: grid;
  align-content: start;
  grid-row-gap: 3rem;
  grid-column-gap: 1.5rem;
  grid-template-columns: 3rem auto;
  position: relative;
  z-index: 1;

  ${media.desktopSmall} {
    grid-template-columns: none;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    height: 50%;
    left: calc(var(--step-cylinder-size) / 2);
    border-left: var(--step-line-solid);

    ${media.desktopSmall} {
      top: calc(var(--step-cylinder-size) / 2);
      left: 0;
      width: 50%;
      height: unset;
      border-bottom: var(--step-line-solid);
    }
  }

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 50%;
    height: 50%;
    left: calc(var(--step-cylinder-size) / 2);
    border-left: var(--step-line-solid);

    ${media.desktopSmall} {
      top: calc(var(--step-cylinder-size) / 2);
      left: 50%;
      width: 50%;
      height: unset;
      border-bottom: var(--step-line-solid);
    }
  }

  &:first-child {
    &:before {
      -webkit-mask-image: var(--gradient-line);
      mask-image: var(--gradient-line);
      --gradient-line: linear-gradient(to bottom, ${gradient});
      border-left: var(--step-line-dashed);

      ${media.desktopSmall} {
        --gradient-line: linear-gradient(to right, ${gradient});
        border-bottom: var(--step-line-dashed);
        border-left: unset;
      }
    }
  }

  &:last-child {
    &:after {
      -webkit-mask-image: var(--gradient-line);
      mask-image: var(--gradient-line);
      --gradient-line: linear-gradient(to top, ${gradient});
      border-left: var(--step-line-dashed);
      ${media.desktopSmall} {
        --gradient-line: linear-gradient(to left, ${gradient});
        border-bottom: var(--step-line-dashed);
        border-left: unset;
      }
    }
  }
`

const GraphicsBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  position: relative;
  width: 3rem;
  height: 100%;

  ${media.desktopSmall} {
    flex-direction: row;
    height: 3rem;
    width: 100%;
  }
`

const Cylinder = styled.div`
  width: var(--step-cylinder-size);
  height: var(--step-cylinder-size);
  background-color: ${colors.white};
  border-radius: 50%;
  border: ${pxToRem(3)} solid ${colors.secondary.lightest};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fontLeagueSpartan};
  font-size: ${pxToRem(18)};
  font-weight: 500;
  letter-spacing: 0.01em;
  padding-top: ${pxToRem(3)};
`

const TextsBlock = styled(SectionTextStyles)`
  display: grid;
  grid-row-gap: 0.5rem;
  align-items: start;
  align-content: start;
  padding: ${pxToRem(24, 24, 24, 0)};

  ${media.desktopSmall} {
    grid-row-gap: 1.5rem;
    padding: ${pxToRem(0, 24)};
  }
`

const StepsSection = styled.div`
  display: grid;
  align-self: center;
  max-width: ${pxToRem(480)};

  ${media.desktopSmall} {
    max-width: unset;
    grid-template-columns: repeat(4, 1fr);
  }
`

export const Steps = { Item, StepsSection }
